import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { LocaleDatePipe } from '@app/pipes/locale-date.pipe';
import { ContentStateComponent } from '@app/shared/content-state/content-state.component';
import { SbSpinnerComponent } from '@app/shared/sb-spinner.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';
import { AbsenteeDaysData } from '@reducers/orm/absence/absence.model';
import { AbsenteeOptionModel } from '@reducers/orm/absentee-option/absentee-option.model';
import { TooltipModule } from '@sb/tooltip';
import { ButtonComponent, IconComponent, SelectComponent, SelectItem } from '@sb/ui';

import { PlanType } from '../../../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { ShiftWarningTooltipComponent } from '../../../../+authenticated/+timesheet/shared/shift-warning/shift-warning-tooltip.component';
import { AbsenceOptionUnit } from '../../../../enums';
import { DecimalToDurationFormatPipe } from '../../../../pipes/decimal-to-duration-format.pipe';
import { TranslationParamsPipe } from '../../../../pipes/translation-params.pipe';
import { DecimalTimeInputComponent } from '../../../../shared/decimal-time-input.component';
import { SubscriptionPlanDirective } from '../../../../shared/subscription-plan/subscription-plan.directive';
import { TimeInputComponent } from '../../../../shared/time-input.component';
import { trackByDateControl } from '../../../../shared/trackby';
import { AbsenceDayOption } from '../../employee-absentee-days/employee-absentee-days.enum';

@Component({
  selector: 'absence-day-controls',
  standalone: true,
  imports: [
    // Modules
    CommonModule,
    TranslateModule,
    TooltipModule,
    ReactiveFormsModule,
    // Directives
    SubscriptionPlanDirective,
    // Pipes
    TranslationParamsPipe,
    DecimalToDurationFormatPipe,
    // Components
    ContentStateComponent,
    SbSpinnerComponent,
    ButtonComponent,
    IconComponent,
    ShiftWarningTooltipComponent,
    TimeInputComponent,
    DecimalTimeInputComponent,
    SelectComponent,
    LocaleDatePipe,
  ],
  templateUrl: './absence-request-day-controls.component.html',
})
export class AbsenceRequestDayControlsComponent {
  public readonly AbsenceOptionUnit = AbsenceOptionUnit;
  public readonly PlanType = PlanType;
  public readonly AbsenceDayOption = AbsenceDayOption;

  public trackByDateControl = trackByDateControl;

  public readonly absenceDayOptions: { label: string; value: AbsenceDayOption }[] = [
    { label: _('Full day'), value: AbsenceDayOption.FULL },
    { label: _('Half day from'), value: AbsenceDayOption.HALF_FROM },
    { label: _('Half day until'), value: AbsenceDayOption.HALF_UNTIL },
    { label: _('No absence'), value: AbsenceDayOption.NONE },
  ];

  @Input()
  public loadingDays: boolean;
  @Input()
  public loadingHours: boolean;
  @Input()
  public isRequestInDays: boolean;
  @Input()
  public canUpdateTimeOffBalances: boolean;

  @Input()
  public dayControls: UntypedFormGroup[];
  @Input()
  public selectedAbsenceType: AbsenteeOptionModel;
  @Input()
  public timeOffBalanceOptions: SelectItem<string>[];
  @Input()
  public absenteeDaysData: Record<string, AbsenteeDaysData> = {};
  @Input()
  public absenceRestrictionConflicts = {};
  @Input()
  public totalDays: number;
  @Input()
  public totalHours: number;

  @Input()
  public isSaving: boolean;

  @Output()
  public setHours = new EventEmitter<void>();
}
