<sb-dialog-header>
  <h3 sb-dialog-title>{{ 'Absence request' | translate }}</h3>
  <knowledge-base-article [article]="'adding-absence'" />
  @if (absence.canDelete || absence.canEdit) {
    <sb-dialog-header-actions>
      @if (absence.canDelete) {
        <button sb-icon-button [color]="'transparent'" (click)="openDeleteConfirm()">
          <sb-icon [name]="'trash'" />
        </button>
      }
      @if (absence.canEdit) {
        <button sb-icon-button [color]="'transparent'" (click)="editAbsence()">
          <sb-icon [name]="'pen'" />
        </button>
      }
    </sb-dialog-header-actions>
  }
</sb-dialog-header>

<sb-dialog-content>
  <div class="flex flex-col gap-6 p-6 pt-0">
    <absence-summary-item
      class="text-word-mark-800"
      [absence]="absence"
      [isOwnAbsence]="isOwnAbsence"
      [showStatus]="true"
      [popoverData]="popoverData$ | async"
    />

    @if (canMarkAsReturned) {
      <sb-form-field
        class="ml-10 py-2.5"
        [control]="form.get('enddate')"
        [fullWidth]="true"
        [noPadding]="true"
        [errorWhen]="['touched']"
        [label]="'End Date' | translate"
        [info]="'Update when known' | translate"
      >
        <date-input
          [formControl]="form.get('enddate')"
          [appendTo]="'body'"
          [fullWidth]="true"
          [minDate]="absence.startdate"
        />
      </sb-form-field>
    }

    @if (absence.note) {
      <p class="my-0 ml-10 py-2.5 text-word-mark-800">"{{ absence.note }}"</p>
    }

    @if (loadingList) {
      <sb-loading-state class="h-52"></sb-loading-state>
    } @else if (list) {
      <sb-status-list [list]="list" />
    }

    <div class="flex flex-col gap-2 text-sm text-support">
      @if (absence.canApprove && absence.roster_action) {
        <div class="flex gap-2">
          <span>
            {{ 'Existing shifts' | translate }}:
            @switch (absence.roster_action) {
              @case (RosterActions.HIDE) {
                {{ 'Remove from schedule' | translate }}
              }
              @case (RosterActions.NONE) {
                {{ 'Leave in schedule' | translate }}
              }
              @case (RosterActions.MOVE_TO_OPEN_SHIFT) {
                {{ 'Move to open shift' | translate }}
              }
            }
          </span>
          <a (click)="editAbsence()" class="text-primary-500 hover:underline">
            {{ 'Edit' | translate }}
          </a>
        </div>
      }
      <div class="flex gap-3">
        @if (absence.CreatedBy) {
          <span>
            {{
              'Requested: [date] by [requester]'
                | translate
                | translationParams
                  : {
                      date: absence.created | localeDate: { weekday: 'short', day: 'numeric', month: 'short' },
                      requester: absence.CreatedBy.name
                    }
            }}
          </span>
        } @else {
          <span>
            {{
              'Requested: [date]'
                | translate
                | translationParams
                  : {
                      date: absence.created | localeDate: { weekday: 'short', day: 'numeric', month: 'short' }
                    }
            }}
          </span>
        }
        @if (absence.status === AbsenceStatus.APPROVED) {
          <span>
            {{
              'Approved: [date] by [supervisor]'
                | translate
                | translationParams
                  : {
                      date: absence.reviewed | localeDate: { weekday: 'short', day: 'numeric', month: 'short' },
                      supervisor: absence.ReviewedBy.name
                    }
            }}
          </span>
        } @else if (absence.status === AbsenceStatus.DECLINED) {
          <span>
            {{
              'Declined: [date] by [supervisor]'
                | translate
                | translationParams
                  : {
                      date: absence.reviewed | localeDate: { weekday: 'short', day: 'numeric', month: 'short' },
                      supervisor: absence.ReviewedBy.name
                    }
            }}
          </span>
        }
      </div>
    </div>
  </div>
</sb-dialog-content>

<sb-dialog-footer>
  @if (canMarkAsReturned) {
    <sb-dialog-footer-actions [align]="'end'">
      <button sb-button [disabled]="saving" sb-dialog-close>
        {{ 'Cancel' | translate }}
      </button>
      <button sb-button [disabled]="loadingList" [sbLadda]="saving" [color]="'primary'" (click)="markAsReturned()">
        {{ 'Update' | translate }}
      </button>
    </sb-dialog-footer-actions>
  } @else if (absence.canApprove) {
    @if (!isOwnAbsence) {
      <sb-dialog-footer-actions>
        <sb-checkbox [formControl]="form.get('notifyEmployee')">
          <div class="flex items-center gap-2">
            {{ 'Notify Employee' | translate }}
            <sb-icon
              [name]="'info-circle'"
              [sbTooltip]="
                'Send an email to the employee informing them their absence is approved or declined' | translate
              "
            />
          </div>
        </sb-checkbox>
      </sb-dialog-footer-actions>
    }
    <sb-dialog-footer-actions [align]="'end'">
      @switch (absence.status) {
        @case (AbsenceStatus.APPROVED) {
          <button sb-button [sbLadda]="saving" (click)="changeStatus(AbsenceStatus.PENDING)">
            {{ 'Pending' | translate }}
          </button>
          <button sb-button [sbLadda]="saving" (click)="changeStatus(AbsenceStatus.DECLINED)">
            {{ 'Decline' | translate }}
          </button>
        }
        @case (AbsenceStatus.DECLINED) {
          <button sb-button [sbLadda]="saving" (click)="changeStatus(AbsenceStatus.PENDING)">
            {{ 'Pending' | translate }}
          </button>
          <button sb-button [sbLadda]="saving" (click)="changeStatus(AbsenceStatus.APPROVED)">
            {{ 'Approve' | translate }}
          </button>
        }
        @case (AbsenceStatus.PENDING) {
          <button sb-button [sbLadda]="saving" (click)="changeStatus(AbsenceStatus.DECLINED)">
            {{ 'Decline' | translate }}
          </button>
          <button sb-button [sbLadda]="saving" [color]="'primary'" (click)="changeStatus(AbsenceStatus.APPROVED)">
            {{ 'Approve' | translate }}
          </button>
        }
      }
    </sb-dialog-footer-actions>
  }
</sb-dialog-footer>
