@for (item of items; track $index) {
  @switch (item.type) {
    @case (ItemType.ICON) {
      <sb-badge-icon [name]="item.icon" [ngClass]="item.classes" [size]="'lg'" />
    }
    @case (ItemType.ABSENCE_BADGE) {
      <sb-badge-icon [absenceBadgeIcon]="item.absence" [size]="'lg'" [ngClass]="item.classes" />
    }
    @case (ItemType.AVATAR) {
      <sb-avatar
        [url]="item.employee.avatar_24x24"
        [firstName]="item.employee.first_name"
        [lastName]="item.employee.last_name"
        [colorSeed]="item.employee.id"
        [size]="'sm'"
        [sbPopover]="popoverTemplate"
        [sbPopoverTrigger]="'hover'"
        [sbPopoverDelay]="500"
        [sbPopoverPosition]="PopoverPlacement.left"
        [sbPopoverDisabled]="!popoverTemplate"
      />
    }
    @case (ItemType.MORE_INDICATOR) {
      <div
        class="flex size-6 items-center justify-center rounded-full bg-black text-xs font-bold text-white"
        [ngClass]="item.classes"
      >
        +{{ item.count }}
      </div>
    }
  }
}
