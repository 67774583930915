import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { EnhancedAbsenceModel } from '@app/reducers/orm/absence/absence.model';
import { EmployeeModel } from '@reducers/orm/employee/employee.model';
import { sbIcons } from '@sb/svg-icons';
import { AvatarComponent, BadgeIconComponent, PopoverDirective, PopoverModule, PopoverPlacement } from '@sb/ui';
import { clsx } from 'clsx';

import { AbsenceBadgeIconDirective } from '../../absence/absence-badge-icon.directive';

export enum Orientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum ItemType {
  ICON = 'icon',
  ABSENCE_BADGE = 'absence-badge',
  AVATAR = 'avatar',
  MORE_INDICATOR = 'more-indicator',
}

// TODO for now this thing will live here instead of ui-lib because of the above imports
@Component({
  selector: 'sb-avatar-badge-group',
  standalone: true,
  imports: [CommonModule, BadgeIconComponent, AbsenceBadgeIconDirective, AvatarComponent, PopoverModule],
  templateUrl: './avatar-badge-group.component.html',
})
export class AvatarBadgeGroupComponent implements OnChanges {
  public ItemType = ItemType;
  public PopoverPlacement = PopoverPlacement;

  @ViewChild(PopoverDirective)
  public popover: PopoverDirective;

  public items = [];
  public _employees: EmployeeModel[] = [];
  public employeeMoreIndicator;

  @Input()
  public icons: sbIcons[] = [];

  @Input()
  public absences: EnhancedAbsenceModel[] = [];

  @Input()
  public employees: EmployeeModel[] = [];

  @Input()
  public truncateEmployeesAfter: number;

  @Input()
  public orientation: Orientation = Orientation.VERTICAL;

  @Input()
  public popoverTemplate: TemplateRef<unknown>;

  @HostBinding('class')
  public get classes() {
    return clsx([
      'flex items-center justify-center',
      this.orientation === Orientation.HORIZONTAL ? 'flex-row-reverse pl-1' : 'flex-col-reverse pt-1',
    ]);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (
      this.truncateEmployeesAfter &&
      this.truncateEmployeesAfter > 0 &&
      this.employees.length > this.truncateEmployeesAfter &&
      changes['employees']
    ) {
      this._employees = this.employees.slice(0, this.truncateEmployeesAfter);
      this.employeeMoreIndicator = {
        type: ItemType.MORE_INDICATOR,
        count: this.employees.length - this.truncateEmployeesAfter,
      };
    } else {
      this._employees = this.employees;
      this.employeeMoreIndicator = null;
    }

    if (changes['icons'] || changes['employees'] || changes['absenceOptions']) {
      this.items = this.getItems();
    }
  }

  private getItems() {
    const items = [];
    if (this.icons.length > 0) {
      items.push(...this.icons.map((icon) => ({ type: ItemType.ICON, icon })).reverse());
    }
    if (this.absences.length > 0) {
      items.push(...this.absences.map((absence) => ({ type: ItemType.ABSENCE_BADGE, absence })).reverse());
    }
    if (this.employeeMoreIndicator) {
      items.push(this.employeeMoreIndicator);
    }
    if (this._employees.length > 0) {
      items.push(...this._employees.map((employee) => ({ type: ItemType.AVATAR, employee })).reverse());
    }

    items.forEach((item) => {
      item.classes = this.getItemClasses(item);
    });

    return items;
  }

  private getItemClasses(item) {
    return clsx([
      'box-content border-2 border-solid',
      this.orientation === Orientation.HORIZONTAL ? '-ml-1' : '-mt-1',
      item.type === ItemType.ABSENCE_BADGE && item.absence?.status === 'Pending' ? '' : 'border-white',
    ]);
  }
}
