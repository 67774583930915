<sb-dialog-header>
  <h3 sb-dialog-title>{{ 'Edit Absence Request' | translate }}: {{ this.absence.Employee.name }}</h3>
  <knowledge-base-article [article]="'my-absence'"></knowledge-base-article>
</sb-dialog-header>

<sb-dialog-content>
  <form sb-dialog-form [formGroup]="form">
    <!-- First row -->
    <div class="flex gap-6">
      <div class="flex w-1/2 flex-col gap-3">
        <sb-form-field
          [errorWhen]="['touched']"
          [fullWidth]="true"
          [noPadding]="true"
          [control]="form.get('absentee_option_id')"
          [label]="'Absence type' | translate"
        >
          <sb-select [formControl]="form.get('absentee_option_id')" [items]="mappedAbsenceTypes" />
        </sb-form-field>

        @if (selectedAbsenceType.allow_open_ended && (Features.TMP_OPEN_ENDED_ABSENCES | featureFlag)) {
          <sb-form-field [fullWidth]="true" [noPadding]="true" [control]="form.get('open_ended')" [showLabel]="false">
            <sb-checkbox [formControl]="form.get('open_ended')">
              <div class="flex items-center gap-2">
                {{ 'Unknown end date' | translate }}
                <sb-icon [name]="'info-circle'" [sbTooltip]="'This can not be modified while editing' | translate" />
              </div>
            </sb-checkbox>
          </sb-form-field>
        }
      </div>
      <absence-request-period-field
        class="w-1/2"
        [periodControl]="form.get('period')"
        [openEnded]="form.get('open_ended').value"
        [hasAbsenceRestrictionConflict]="hasAbsenceRestrictionConflict"
      />
    </div>
    <!-- Second row -->
    <div class="flex gap-6">
      <div class="flex w-1/2 flex-col gap-6">
        <!-- TODO not really a form field, but I need consistently styled labels -->
        <sb-form-field [fullWidth]="true" [noPadding]="true" [label]="'Absence duration requested' | translate">
          {{
            isRequestInDays
              ? (totalDays | decimalToDurationFormat: absenceOptionUnit.DAYS)
              : (totalHours | decimalToDurationFormat: absenceOptionUnit.HOURS)
          }}
        </sb-form-field>

        @if (expectedHoursReceived | async) {
          <absence-expected-hours [expectedHours]="expectedHours" [canApprove]="absence.canApprove" />
        }
      </div>
      @if (canViewTimeOffBalances && timeOffBalanceOptions.length > 0) {
        <div class="w-1/2" *subscriptionPlan="PlanType.BASIC">
          <absence-balance-stats
            [employee]="absence.Employee"
            [periodCtrl]="form.get('period')"
            [absenceTypeCtrl]="form.get('absentee_option_id')"
            [timeOffBalanceOptions]="timeOffBalanceOptions"
          />
        </div>
      }
    </div>
    <!-- Third row -->
    <!-- TODO eventually we should refactor these components so that logic lives in this actual component instead of passing around all these inputs -->
    <absence-day-controls
      [loadingDays]="loadingDays"
      [loadingHours]="loadingHours"
      [canUpdateTimeOffBalances]="canUpdateTimeOffBalances"
      [absenteeDaysData]="absenteeDaysData"
      [absenceRestrictionConflicts]="absenceRestrictionConflicts"
      [selectedAbsenceType]="selectedAbsenceType"
      [dayControls]="dayControls"
      [totalDays]="totalDays"
      [totalHours]="totalHours"
      [timeOffBalanceOptions]="timeOffBalanceOptions"
      [isRequestInDays]="isRequestInDays"
      (setHours)="setHours()"
    />
    <!-- Forth row -->
    @if (form.get('note').enabled) {
      <sb-form-field
        [errorWhen]="['touched']"
        [fullWidth]="true"
        [noPadding]="true"
        [control]="form.get('note')"
        [label]="'Note' | translate"
      >
        <textarea
          class="min-h-three-lines"
          formControlName="note"
          [placeholder]="('Note' | translate) + '...'"
        ></textarea>
      </sb-form-field>
    }
    <!-- Fifth row -->
    @if (form.get('roster_action').enabled || hasWaitHours) {
      <div class="grid grid-cols-2 gap-4">
        @if (form.get('roster_action').enabled) {
          <sb-form-field
            [control]="form.get('roster_action')"
            [fullWidth]="true"
            [noPadding]="true"
            [label]="'Existing shifts' | translate"
            [info]="'What should happen to existing shifts?' | translate"
          >
            <select [formControl]="form.get('roster_action')" sbInput>
              <option [value]="RosterActions.HIDE">{{ 'Remove from schedule' | translate }}</option>
              <option [value]="RosterActions.NONE">{{ 'Leave in schedule' | translate }}</option>
              <option *subscriptionPlan="PlanType.BASIC" [value]="RosterActions.MOVE_TO_OPEN_SHIFT">
                {{ 'Move to open shift' | translate }}
              </option>
            </select>
          </sb-form-field>
        }
        @if (hasWaitHours) {
          <sb-form-field
            [errorWhen]="['touched']"
            [fullWidth]="true"
            [noPadding]="true"
            [control]="form.get('wait_days')"
            [label]="'Wait days' | translate"
            [info]="
              'Here you’ll fill in the number of wait days. Automatically this will only be applied to the days with hours.'
                | translate
            "
          >
            <div class="flex flex-col gap-1">
              <sb-select [formControl]="form.get('wait_days')" [items]="waitHoursRange" />
              @if (form.get('wait_days').value > 0) {
                {{ totalWaitHours | decimalToTime }} {{ 'Hours' | translate | lowercase }}
              }
            </div>
          </sb-form-field>
        }
      </div>
    }

    <!-- Sixth row -->
    @if (form.get('hide_days_without_hours').enabled) {
      <sb-form-field
        [fullWidth]="true"
        [noPadding]="true"
        [control]="form.get('hide_days_without_hours')"
        [showLabel]="false"
      >
        <sb-checkbox [formControl]="form.get('hide_days_without_hours')">
          <div class="flex items-center gap-2">
            {{ 'Hide absentee days without value' | translate }}
            <sb-icon
              [name]="'info-circle'"
              [sbTooltip]="'Absentee days without value will not be shown in the schedule and timesheets.' | translate"
            />
          </div>
        </sb-checkbox>
      </sb-form-field>
    }
  </form>
</sb-dialog-content>

<sb-dialog-footer>
  <sb-dialog-footer-actions [align]="'end'">
    <button sb-button [color]="'transparent'" (click)="onBack()">
      {{ 'Back' | translate }}
    </button>
    <button sb-button [sbLadda]="loading" [color]="'primary'" (click)="onSave()" [disabled]="form.pristine">
      {{ 'Save' | translate }}
    </button>
  </sb-dialog-footer-actions>
</sb-dialog-footer>
