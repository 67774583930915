<div class="flex items-start gap-3">
  <sb-avatar-badge-group
    class="shrink-0"
    [absences]="[absence]"
    [employees]="isOwnAbsence ? [] : [absence.Employee]"
    [popoverTemplate]="isOwnAbsence ? null : employeePopover"
  />
  <sb-summary-item [compact]="compact">
    <span
      firstRowLeft
      [sbPopover]="employeePopover"
      [sbPopoverTrigger]="'hover'"
      [sbPopoverDelay]="500"
      [sbPopoverPosition]="PopoverPlacement.left"
      [sbPopoverDisabled]="!popoverData || isOwnAbsence"
    >
      {{ isOwnAbsence ? absence.absenteeOption.option : absence.Employee.name }}
    </span>
    @if (inline) {
      <ng-container firstRowRight *ngTemplateOutlet="duration" />
    }
    <ng-container secondRowLeft>
      @if (!isOwnAbsence) {
        <span class="truncate" [sbTooltip]="absence.absenteeOption.option">
          {{ absence.absenteeOption.option }}
        </span>
      }
      @if (showStatus) {
        @switch (absence.status) {
          @case ('Approved') {
            <span class="text-success-500">{{ 'Approved' | translate }}</span>
          }
          @case ('Declined') {
            <span class="text-error-500">{{ 'Declined' | translate }}</span>
          }
          @default {
            <span>{{ 'Pending' | translate }}</span>
          }
        }
      }
    </ng-container>
    @if (inline) {
      <ng-container secondRowRight *ngTemplateOutlet="dateRange" />
    }
    @if (!inline) {
      <ng-container thirdRowLeft>
        <ng-container *ngTemplateOutlet="dateRange" />
        @if (!absence.open_ended) {
          <ng-container *ngTemplateOutlet="duration" />
        }
      </ng-container>
    }
  </sb-summary-item>
</div>

<ng-template #dateRange>
  <span
    [ngClass]="{ 'cursor-pointer font-semibold text-primary-500 hover:underline': rangeClickShouldNavigate }"
    (click)="redirectToSchedule($event)"
  >
    {{ absence.startdate | localeDate: { weekday: 'short', month: 'short', day: 'numeric' } }}

    @if (absence.open_ended) {
      - <span class="font-bold">{{ 'unknown' | translate }}</span>
    } @else if (absence.startdate !== absence.enddate) {
      - {{ absence.enddate | localeDate: { weekday: 'short', month: 'short', day: 'numeric' } }}
    }
  </span>
</ng-template>

<ng-template #duration>
  @if (inline) {
    <span>
      @if (absence.absenteeOption?.unit === AbsenceOptionUnit.DAYS) {
        {{ absence.day_total | decimalToDurationFormat: AbsenceOptionUnit.DAYS }}
      } @else {
        {{ absence.hours | decimalToDurationFormat: AbsenceOptionUnit.HOURS }}
      }
    </span>
  } @else {
    <span>
      @if (absence.absenteeOption?.unit === AbsenceOptionUnit.DAYS) {
        ({{ absence.day_total | decimalToDurationFormat: AbsenceOptionUnit.DAYS }})
      } @else {
        ({{ absence.hours | decimalToDurationFormat: AbsenceOptionUnit.HOURS }})
      }
    </span>
  }
</ng-template>

<ng-template #employeePopover>
  @if (popoverData) {
    <sb-employee-popover
      [id]="popoverData?.id"
      [firstName]="popoverData?.firstName"
      [lastName]="popoverData?.lastName"
      [avatarUrl]="popoverData?.avatarUrl"
      [location]="popoverData?.location"
      [email]="popoverData?.email"
      [phone]="popoverData?.phone"
      [department]="popoverData?.department"
      [team]="popoverData?.team"
      [jobTitle]="popoverData?.jobTitle"
      [contractEndDate]="popoverData?.contractEndDate"
      [showProfileButton]="popoverData?.showProfileButton"
      (viewProfile)="goToEmployeePage()"
    />
  }
</ng-template>
