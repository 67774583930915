import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleDatePipe } from '@app/pipes/locale-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@sb/tooltip';
import {
  EmployeePopoverComponent,
  PopoverDirective,
  PopoverModule,
  PopoverPlacement,
  SbDialogService,
  SummaryItemComponent,
} from '@sb/ui';

import { LocaleService } from '../../../+authenticated/shared/locale/locale.service';
import { AbsenceOptionUnit } from '../../../enums';
import { DecimalToDurationFormatPipe } from '../../../pipes/decimal-to-duration-format.pipe';
import { EnhancedAbsenceModel } from '../../../reducers/orm/absence/absence.model';
import { AvatarBadgeGroupComponent } from '../../sb-lib/avatar-badge-group/avatar-badge-group.component';

export interface EmployeePopoverData {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl?: string;
  phone?: string;
  email?: string;
  contractEndDate?: string;
  showProfileButton?: boolean;
  location?: string;
  department?: string;
  team?: string;
  jobTitle?: string;
}

@Component({
  standalone: true,
  selector: 'absence-summary-item',
  imports: [
    AvatarBadgeGroupComponent,
    TranslateModule,
    LocaleDatePipe,
    DecimalToDurationFormatPipe,
    NgTemplateOutlet,
    TooltipModule,
    SummaryItemComponent,
    NgClass,
    PopoverModule,
    EmployeePopoverComponent,
  ],
  templateUrl: './absence-summary-item.component.html',
  providers: [LocaleService, SbDialogService],
})
export class AbsenceSummaryItemComponent {
  public PopoverPlacement = PopoverPlacement;
  public AbsenceOptionUnit = AbsenceOptionUnit;
  private _absence: EnhancedAbsenceModel;

  @ViewChild(PopoverDirective)
  private popover: PopoverDirective;

  @ViewChild(AvatarBadgeGroupComponent)
  private avatarBadgeGroup: AvatarBadgeGroupComponent;

  public constructor(
    private router: Router,
    private dialogService: SbDialogService,
  ) {}

  @Input()
  public set absence(absence: EnhancedAbsenceModel) {
    this._absence = absence;
  }
  public get absence(): EnhancedAbsenceModel {
    return this._absence;
  }

  @Input()
  public isOwnAbsence: boolean;

  @Input()
  public showStatus: boolean;

  @Input()
  public rangeClickShouldNavigate: boolean;

  // Display data related to date range and duration inline
  @Input()
  public inline: boolean;

  // Use smaller font sizes (used when used as a notification item in the sidebar)
  @Input()
  public compact: boolean;

  @Input()
  public popoverData: EmployeePopoverData;

  public redirectToSchedule(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.rangeClickShouldNavigate) {
      this.router.navigate(['schedule', 'employee', 'week', { date: this.absence.startdate }]);
    }
  }

  public goToEmployeePage() {
    this.dialogService.closeAll();
    this.popover.close();
    this.avatarBadgeGroup.popover.close();
    this.router.navigate(['employees', this.absence.Employee.id]);
  }
}
